import {
  isErrorType,
  isFetchPersonalSettingsErrorType,
} from '../../types/apis/apiErrorTypeGuard';
import callPersonalSettingsUpdateApi, {
  UPDATE_PERSONAL_SETTINGS_RESULT_CODE,
} from '../../apis/callPersonalSettingsUpdateApi';
import callPersonalSettingsFetchApi, {
  FETCH_PERSONAL_SETTINGS_ERROR_TYPE,
  FETCH_PERSONAL_SETTINGS_RESULT_CODE,
} from '../../apis/callPersonalSettingsFetchApi';
import { ALL_ERROR_TYPE, SERVER_MESSAGE } from '../../constants/constants';
import {
  FetchPersonalSettingsApiResponse,
  UpdatePersonalSettingsApiResponse,
} from '../../types/apis/personalSettingsApi.d';

export const updatePersonalSettingsUtil = async (
  fileData: FormData,
): Promise<{ statusCode: number; message: string }> => {
  // personal settings update API call
  try {
    const apiResponse: UpdatePersonalSettingsApiResponse =
      await callPersonalSettingsUpdateApi(fileData);
    switch (apiResponse.message) {
      // success
      case UPDATE_PERSONAL_SETTINGS_RESULT_CODE.SUCCESSFULLY_UPDATED:
        return {
          statusCode: apiResponse.statusCode,
          message: UPDATE_PERSONAL_SETTINGS_RESULT_CODE.SUCCESSFULLY_UPDATED,
        };
      case SERVER_MESSAGE.OLD_AND_NEW_PASSWORD_SAME:
        return {
          statusCode: apiResponse.statusCode,
          message: SERVER_MESSAGE.OLD_AND_NEW_PASSWORD_SAME,
        };

      case UPDATE_PERSONAL_SETTINGS_RESULT_CODE.WARN_INVALID_AUTH:
        throw UPDATE_PERSONAL_SETTINGS_RESULT_CODE.WARN_INVALID_AUTH;
      case SERVER_MESSAGE.WARN_INPUT_PARAM:
        throw SERVER_MESSAGE.WARN_INPUT_PARAM;
      case SERVER_MESSAGE.WRONG_CREDENTIALS:
        throw SERVER_MESSAGE.WRONG_CREDENTIALS;
      case SERVER_MESSAGE.USER_DEACTIVATED:
        throw SERVER_MESSAGE.USER_DEACTIVATED;
      default:
        throw UPDATE_PERSONAL_SETTINGS_RESULT_CODE.ERR_UNKNOWN;
    }
  } catch (error) {
    let message: ALL_ERROR_TYPE = SERVER_MESSAGE.ERR_UNKNOWN;
    if (isErrorType(error)) {
      message = error;
    }

    return {
      statusCode: 0,
      message,
    };
  }
};

export const fetchPersonalSettingsUtil =
  async (): Promise<FetchPersonalSettingsApiResponse> => {
    // personal settings acquisition API call
    try {
      const apiResponse: FetchPersonalSettingsApiResponse =
        await callPersonalSettingsFetchApi();
      switch (apiResponse.message) {
        // success
        case FETCH_PERSONAL_SETTINGS_RESULT_CODE.SUCCESSFULLY_FETCHED:
          return {
            statusCode: apiResponse.statusCode,
            message: FETCH_PERSONAL_SETTINGS_RESULT_CODE.SUCCESSFULLY_FETCHED,
            details: apiResponse.details,
          };

        case FETCH_PERSONAL_SETTINGS_RESULT_CODE.WARN_INVALID_AUTH:
          throw FETCH_PERSONAL_SETTINGS_RESULT_CODE.WARN_INVALID_AUTH;
        case FETCH_PERSONAL_SETTINGS_RESULT_CODE.USER_ROLE_CHANGED:
          throw FETCH_PERSONAL_SETTINGS_RESULT_CODE.USER_ROLE_CHANGED;
        case SERVER_MESSAGE.USER_CONSENT_REQUIRED:
          throw SERVER_MESSAGE.USER_CONSENT_REQUIRED;
        case SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED:
          throw SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED;
        case SERVER_MESSAGE.ACCESS_PERMISSION_DENIED:
          throw SERVER_MESSAGE.ACCESS_PERMISSION_DENIED;
        case SERVER_MESSAGE.INVALID_TOKEN:
          throw SERVER_MESSAGE.INVALID_TOKEN;
        case SERVER_MESSAGE.EXPIRED_TOKEN:
          throw SERVER_MESSAGE.EXPIRED_TOKEN;
        case SERVER_MESSAGE.USER_DEACTIVATED:
          throw SERVER_MESSAGE.USER_DEACTIVATED;
        case FETCH_PERSONAL_SETTINGS_RESULT_CODE.INVALID_AUTH0_TOKEN:
          throw FETCH_PERSONAL_SETTINGS_RESULT_CODE.INVALID_AUTH0_TOKEN;
        default:
          throw FETCH_PERSONAL_SETTINGS_RESULT_CODE.ERR_UNKNOWN;
      }
    } catch (error ) {

      let resultCode: ALL_ERROR_TYPE =
        FETCH_PERSONAL_SETTINGS_RESULT_CODE.ERR_UNKNOWN;
      if (isFetchPersonalSettingsErrorType(error)) {
        resultCode = error;
      }

      return {
        statusCode: 0,
        message: resultCode,
        details: {
          extension: '',
          accountInfoStatus: '',
          groupNameList: [],
          imageUrl: '',
          mailAddress: '',
          numberOfGroups: 0,
          invitedDt: '',
          registeredDt: '',
          roleName: '',
          userName: '',
          localeCode: '',
        },
      };
    }
  };
