import callApiUtil from '../utils/callApiUtil';
import { API_URL, REQUEST_HEADER_KEY } from '../constants/constants';
import { FetchPersonalSettingsApiResponse } from '../types/apis/personalSettingsApi.d';

/**
 * Personal Settings Information Acquisition API Result Code
 */
export const FETCH_PERSONAL_SETTINGS_RESULT_CODE = {
  SUCCESSFULLY_FETCHED: 'SUCCESSFULLY_FETCHED',
  // Invalid permission error
  WARN_INVALID_AUTH: 'WARN_INVALID_AUTH',
  // Unknown error
  ERR_UNKNOWN: 'ERR_UNKNOWN',
  USER_ROLE_CHANGED: 'USER_ROLE_CHANGED',
  USER_DEACTIVATED : 'USER_DEACTIVATED',
  INVALID_AUTH0_TOKEN : 'INVALID_AUTH0_TOKEN',
} as const;

/**
 * Personal Settings Information
 */
export type FETCH_PERSONAL_SETTINGS_ERROR_TYPE =
  (typeof FETCH_PERSONAL_SETTINGS_RESULT_CODE)[keyof typeof FETCH_PERSONAL_SETTINGS_RESULT_CODE];

/**
 *Personal Settings Information acquisition API call
 *
 * @param accessKey access key
 * @returns Response from the Personal Settings Information acquisition API
 */
const callPersonalSettingsFetchApi =
  (): Promise<FetchPersonalSettingsApiResponse> => {
    const response = callApiUtil
      .get<FetchPersonalSettingsApiResponse>(
        `${API_URL.FETCH_PERSONAL_SETTINGS}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then((apiResponse) => apiResponse.data);

    return response;
  };

export default callPersonalSettingsFetchApi;
