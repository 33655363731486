import { useAuth0 } from '@auth0/auth0-react';
import { signOut } from 'firebase/auth';
import callLogOutApi from '../apis/callLogOutApi';
import useDrawerInfo from './useDrawerInfo';
import { fireBaseAuth } from '../firebase/firebase';
import useSnackbar from './useSnackbar';

/**
 * 本カスタムフックからの返却値
 */
export type UseLogoutValue = {
  // ［ログアウト］ボタンクリック処理
  onClickLogoff: () => void;
};

/**
 * ログアウト hooks
 *
 * @returns
 */
const useLogout = (): UseLogoutValue => {

  const { logout } = useAuth0();
  const { closeDrawer } = useDrawerInfo();
  const { displaySnackbar } = useSnackbar();


  /**
   * Handles the logoff process by calling the backend API to log out the user from the backend,
   * then logging out from Firebase and Okta Auth0, closing the drawer, and displaying
   * appropriate messages based on the success or failure of the operations.
   */
  const onClickLogoff = (): void => {
    void callLogOutApi()
      .then(() => {
        signOut(fireBaseAuth)
          .then((resp) => console.log(resp))
          .catch((error) => console.log(error));
        void logout({ logoutParams: { returnTo: window.location.origin } })
          .then(() => {
            console.info('Logout Success');
          })
          .catch((err) => {
            console.log(err);
            displaySnackbar({
              message: 'Failed to Logout.',
              timeout: 3001,
            });
          })
          .finally(() => {
            localStorage.removeItem('OktaToken');
          });
      })
      .catch((err) => {
        console.log(err);
        displaySnackbar({
          message: 'Failed to Logout.',
          timeout: 3001,
        });
      });

      
      closeDrawer();
        
  };

  return {
    onClickLogoff,
  };
};

export default useLogout;
