/* eslint-disable import/prefer-default-export */
import callGetCorporateDetailsApi, {
  CORPORATE_DETAILS_API_ERROR_TYPE,
  CORPORATE_DETAILS_API_RESULT_CODE,
} from '../../apis/callGetCorporateDetailsApi';
import callUpdateCorporateDetailsApi, {
  UPDATE_CORPORATE_DETAILS_API_ERROR_TYPE,
  UPDATE_CORPORATE_DETAILS_API_RESULT_CODE,
} from '../../apis/callUpdateCorporateDetailsApi';
import { SERVER_MESSAGE } from '../../constants/constants';
import {
  isGetCorporateDetailsErrorType,
  isUpdateCorporateDetailsErrorType,
} from '../../types/apis/apiErrorTypeGuard';
import {
  CorporateDetails,
  CorporateDetailsApiRequestParameter,
  CorporateDetailsApiResponse,
  UpdateCorporateDetailsApiRequest,
  UpdateCorporateDetailsApiResponse,
} from '../../types/apis/corporateDetailsApi.d';

export type CorporateProfileReturnType = {
  message: string;
  details: CorporateDetails;
};

export const getCorporateProfile = async (
  requestParams: CorporateDetailsApiRequestParameter,
): Promise<CorporateProfileReturnType> => {
  // Corporate Details acquisition API call
  try {
    const apiResponse: CorporateDetailsApiResponse =
      await callGetCorporateDetailsApi(requestParams);
    switch (apiResponse.message) {
      // success
      case CORPORATE_DETAILS_API_RESULT_CODE.SUCCESSFULLY_FETCHED:
        return apiResponse;

      // Failure
      case CORPORATE_DETAILS_API_RESULT_CODE.INFO_NO_CORPORATION_DETAIL:
        throw CORPORATE_DETAILS_API_RESULT_CODE.INFO_NO_CORPORATION_DETAIL;
      case CORPORATE_DETAILS_API_RESULT_CODE.INFO_NOTHING_KEY:
        throw CORPORATE_DETAILS_API_RESULT_CODE.INFO_NOTHING_KEY;
      case CORPORATE_DETAILS_API_RESULT_CODE.INFO_EXPIRED:
        throw CORPORATE_DETAILS_API_RESULT_CODE.INFO_EXPIRED;
      case CORPORATE_DETAILS_API_RESULT_CODE.WARN_INVALID_AUTH:
        throw CORPORATE_DETAILS_API_RESULT_CODE.WARN_INVALID_AUTH;
      case CORPORATE_DETAILS_API_RESULT_CODE.AUTH_NOT_ALLOWED:
        throw CORPORATE_DETAILS_API_RESULT_CODE.AUTH_NOT_ALLOWED;
      case CORPORATE_DETAILS_API_RESULT_CODE.CORP_DETAIL_NOT_FOUND:
        throw CORPORATE_DETAILS_API_RESULT_CODE.CORP_DETAIL_NOT_FOUND;
      case SERVER_MESSAGE.USER_CONSENT_REQUIRED:
        throw SERVER_MESSAGE.USER_CONSENT_REQUIRED;
      case SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED:
        throw SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED;
      case SERVER_MESSAGE.USER_ROLE_CHANGED:
        throw SERVER_MESSAGE.USER_ROLE_CHANGED;
      case SERVER_MESSAGE.INVALID_TOKEN:
        throw SERVER_MESSAGE.INVALID_TOKEN;
      case SERVER_MESSAGE.EXPIRED_TOKEN:
        throw SERVER_MESSAGE.EXPIRED_TOKEN;
      case SERVER_MESSAGE.USER_DEACTIVATED:
        throw SERVER_MESSAGE.USER_DEACTIVATED;
      case SERVER_MESSAGE.INVALID_AUTH0_TOKEN:
        throw SERVER_MESSAGE.INVALID_AUTH0_TOKEN;
      default:
        throw CORPORATE_DETAILS_API_RESULT_CODE.ERR_UNKNOWN; // WARN_NO_AUTH_LIST, treats everything as ERR_UNKNOWN except for login authentication errors
    }
  } catch (error) {
    let resultCode: CORPORATE_DETAILS_API_ERROR_TYPE =
      CORPORATE_DETAILS_API_RESULT_CODE.ERR_UNKNOWN;
    if (isGetCorporateDetailsErrorType(error)) {
      resultCode = error;
    }

    return {
      message: resultCode,
      details: {
        corporateContactResponse: {
          corpId: '',
          email: '',
        },
        corporateInfoResponse: { corporateName: 'Name' },
        subscriptionInfoResponse: {
          subscriptionLink: '',
          currentPlan: '',
          expiredDate: '',
          nextBillingDate: '',
          trialFlag: false,
        },
        orderInfoResponse: { orderHistoryLink: '' },
        paymentInfoResponse: { paymentInfoLink: '' },
      },
    };
  }
};

export const updateCorporateProfile = async (
  requestParams: UpdateCorporateDetailsApiRequest,
): Promise<UpdateCorporateDetailsApiResponse> => {
  // Corporate Details acquisition API call
  try {
    const apiResponse: UpdateCorporateDetailsApiResponse =
      await callUpdateCorporateDetailsApi(requestParams);
    switch (apiResponse.message) {
      // success
      case UPDATE_CORPORATE_DETAILS_API_RESULT_CODE.SUCCESSFULLY_UPDATED:
        return apiResponse;

      // Failure
      case UPDATE_CORPORATE_DETAILS_API_RESULT_CODE.AUTH_NOT_ALLOWED:
        throw UPDATE_CORPORATE_DETAILS_API_RESULT_CODE.AUTH_NOT_ALLOWED;
      case UPDATE_CORPORATE_DETAILS_API_RESULT_CODE.INFO_NOTHING_KEY:
        throw UPDATE_CORPORATE_DETAILS_API_RESULT_CODE.INFO_NOTHING_KEY;
      case UPDATE_CORPORATE_DETAILS_API_RESULT_CODE.WARN_INVALID_AUTH:
        throw UPDATE_CORPORATE_DETAILS_API_RESULT_CODE.WARN_INVALID_AUTH;
      case UPDATE_CORPORATE_DETAILS_API_RESULT_CODE.USER_IS_NOT_ADMIN:
        throw UPDATE_CORPORATE_DETAILS_API_RESULT_CODE.USER_IS_NOT_ADMIN;
      case SERVER_MESSAGE.USER_CONSENT_REQUIRED:
        throw SERVER_MESSAGE.USER_CONSENT_REQUIRED;
      case SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED:
        throw SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED;
      case SERVER_MESSAGE.USER_ROLE_CHANGED:
        throw SERVER_MESSAGE.USER_ROLE_CHANGED;
      case SERVER_MESSAGE.INVALID_TOKEN:
        throw SERVER_MESSAGE.INVALID_TOKEN;
      case SERVER_MESSAGE.EXPIRED_TOKEN:
        throw SERVER_MESSAGE.EXPIRED_TOKEN;
      default:
        throw UPDATE_CORPORATE_DETAILS_API_RESULT_CODE.ERR_UNKNOWN;
    }
  } catch (error) {
    let resultCode: UPDATE_CORPORATE_DETAILS_API_ERROR_TYPE =
      UPDATE_CORPORATE_DETAILS_API_RESULT_CODE.ERR_UNKNOWN;
    if (isUpdateCorporateDetailsErrorType(error)) {
      resultCode = error;
    }

    return {
      statusCode: 400,
      message: resultCode,
    };
  }
};
