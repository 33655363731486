import { useState } from 'react';
import useSnackbar from './useSnackbar';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';
import { SERVER_MESSAGE } from '../constants/constants';
import type {
  DeviceSaveHistoryEnableApiRequestParam,
  DeviceSaveHistoryEnableAPIResponse,
} from '../types/apis/deviceSaveHistoryEnableApi';
import {
  DEVICE_SAVE_HISTORY_LOCAL_API_RESULT_CODE,
  DEVICE_SAVE_HISTORY_LOCAL_API_ERROR_TYPE,
} from '../apis/callDeviceSaveHistoryEnable';
import { saveHistoryLocal } from '../utils/devicelist/deviceSaveHistoryLocal';

const useDeviceSaveHistoryEnable = () => {
  
  const [isCallingSaveHistoryApi, setIsCallingSaveHistoryApi] =
    useState<boolean>(false);
  const [saveHistoryErrorType, setSaveHistoryErrorType] =
    useState<DEVICE_SAVE_HISTORY_LOCAL_API_ERROR_TYPE | null>(null);
    const { t } = useSwitchLocaleLanguage();
  const { displaySnackbar } = useSnackbar();

  const onClickDeviceSaveHistoryLocal = (
    imei: string,
    saveInLocalFlag: boolean,
  ): void => {
    const requestParam: DeviceSaveHistoryEnableApiRequestParam = {
      imei,
      saveInLocalFlag,
    };
    // loading Start
    setIsCallingSaveHistoryApi(true);

    /*eslint-disable*/
    saveHistoryLocal(requestParam).then(
      (result: DeviceSaveHistoryEnableAPIResponse) => {
        setSaveHistoryErrorType(result.resultCode);
        if (
          result.resultCode ===
          DEVICE_SAVE_HISTORY_LOCAL_API_RESULT_CODE.SUCCESSFULLY_CREATED
        ) {
          setIsCallingSaveHistoryApi(false);
          displaySnackbar({
            message: saveInLocalFlag? t('saveHistoryLocal.activationSuccess') : t('saveHistoryLocal.deactivationSuccess'),
            timeout: 3001,
          });
          return;
        }

        if (
          result.resultCode === SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED ||
          result.resultCode === SERVER_MESSAGE.USER_ROLE_CHANGED ||
          result.resultCode === SERVER_MESSAGE.INVALID_TOKEN ||
          result.resultCode === SERVER_MESSAGE.EXPIRED_TOKEN
        ) {
          setIsCallingSaveHistoryApi(false);
          return;
        }
        setIsCallingSaveHistoryApi(false);
        displaySnackbar({
          message: saveInLocalFlag? t('saveHistoryLocal.activationFailed') : t('saveHistoryLocal.deactivationFailed'),
          type: 'error',
          timeout: 3001,
        });
      },
    );
  };
  return {
    isCallingSaveHistoryApi,
    onClickDeviceSaveHistoryLocal,
    saveHistoryErrorType,
  };
};

export default useDeviceSaveHistoryEnable;
