import { AxiosError } from 'axios';
import callApiUtil from '../utils/callApiUtil';
import type { RemoteFeatureSetupInfoResponse } from '../types/apis/remoteFeatureSetupInfo.d';
import { API_URL, REQUEST_HEADER_KEY } from '../constants/constants';

/**
 * User List Acquisition API Result Code
 */
export const REMOTE_FEATURE_SETUP_INFO_API_RESULT_CODE = {
  // OK
  OK: 'OK',
  // Failed to get authorization list
  WARN_NO_AUTH_LIST: 'WARN_NO_AUTH_LIST',
  WARN_INVALID_AUTH: 'WARN_INVALID_AUTH',
  // No Group Access
  GROUP_ACCESS_DENIED: 'GROUP_ACCESS_DENIED',
  // Input param problem
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  // No Access Key
  INFO_NOTHING_KEY: 'INFO_NOTHING_KEY',
  INFO_NO_GROUP: 'INFO_NO_GROUP',
  // Unknown error
  ERR_UNKNOWN: 'ERR_UNKNOWN',
} as const;

/**
 * User list acquisition error type
 */
export type REMOTE_FEATURE_SETUP_INFO_API_ERROR_TYPE =
  (typeof REMOTE_FEATURE_SETUP_INFO_API_RESULT_CODE)[keyof typeof REMOTE_FEATURE_SETUP_INFO_API_RESULT_CODE];

/**
 * User list acquisition API call
 *
 * @param accessKey access key
 * @returns Response from the user list acquisition API
 */
const callRemoteFeatureSetupInfoApi = (
  groupId: number,
): Promise<RemoteFeatureSetupInfoResponse> => {

  // Send request (GET)
  const response = callApiUtil
    .get<RemoteFeatureSetupInfoResponse>(
      `${API_URL.REMOTE_DEVICE_SETUP_INFO_GET_API}?groupInfoId=${groupId}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((apiResponse) => apiResponse.data)
    .catch((error: AxiosError) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      statusCode: error.response?.data?.statusCode,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      message: error.response?.data?.message,
      details: {
        groupPin: '',
        sleepTime: '',
        dataDeleteTime: '',
        remoteSettingModeId: 0,
        mobileDataEditable: 0,
        wifiEditable: 0,
        wifiSettings: [],
        updateDt: '',
      },
    }));

  return response;
};

export default callRemoteFeatureSetupInfoApi;
