import React, { useRef, useState } from 'react';
import SimpleBarReact from 'simplebar-react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { Box, Skeleton, Typography, IconButton } from '@mui/material';
import { useStyles } from './DialogStyle';
import { ReactComponent as CloseIcon } from '../../images/icon/close-gray.svg';
import useRemoteFeatureLimitList from '../../hooks/useRemoteFeatureLimitList';
import useRemoteFeatureSetupInfo from '../../hooks/useRemoteFeatureSetupInfo';

import { REMOTE_FEATURE_SETUP_INFO_API_RESULT_CODE } from '../../apis/callRemoteFeatureSetupInfoApi';
import NewWifi from '../Modecules/NewWifi';
import { sendGAEvent } from '../../hooks/useGAEvent';
import useAccountInfo from '../../hooks/useAccountInfo';
import { FEATURE_PERMISSION } from '../../constants/constants';
import useRemoteFeatureSetupPost from '../../hooks/useRemoteFeatureSetupPost';
import Loader from '../Atoms/Loader';

dayjs.extend(utc);

type Props = {
  open: boolean;
  onClose: () => void;
};

const RemoteDeviceSetupDialog = React.memo<Props>((props): JSX.Element => {
  const [isSaveButtonDisable, setSaveButtonDisable] = useState<boolean>(true);
  const formRef = useRef<HTMLFormElement | null>(null);

  const [isMenuOpen, setMenuOpen] = React.useState(false);

  const { open, onClose } = props;
  const classes = useStyles();
  const { accountInfo } = useAccountInfo();
  const {
    isCallingRemoteFeatureSetupPostApi,
    featureSetupResult,
    onClickRemoteFeatureSetupPost,
  } = useRemoteFeatureSetupPost();

  /*eslint-disable*/
  const {
    featureSetupData,
    featureSetupInfoErrorType,
    t,
    isCallingRemoteFeatureSetupInfoApi,
  } = useRemoteFeatureSetupInfo();

  const { featureLimitListData } = useRemoteFeatureLimitList();

  const triggerSubmit = () => {
    if (formRef?.current) {
      formRef?.current?.submit();
    }
  };

  return (
    <Dialog
      open={open}
      className={`${classes.remotDeviceSetupDialog} ${'remove-device-setup'}`}
    >
      <SimpleBarReact
        className={`${
          classes.remotDeviceSetupDialogScroll
        } ${'remove-device-setup-scroll'}`}
        style={isMenuOpen ? { paddingBottom: '80px' } : { paddingBottom: '' }}
      >
        {featureSetupInfoErrorType ===
          REMOTE_FEATURE_SETUP_INFO_API_RESULT_CODE.WARN_INVALID_AUTH &&
          onClose()}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: '14px',
          }}
        >
          <Typography className={classes.remotDeviceSetupTitle}>
            {t('remoteDevice.label.title')}
          </Typography>
          <IconButton
            sx={{ p: '7px', mr: '-7px' }}
            onClick={() => {
              onClose();
              // GA tag
              sendGAEvent('press_btn', 'btn_name', 'exit');
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Typography
          sx={{
            fontWeight: 'light',
            lineHeight: '24px',
            fontSize: '13px',
            height: '19px',
            color: '#939598',
            display: 'flex',
            marginBottom: '22px',
          }}
        >
          {t('remoteDevice.label.lastUpdate')}{' '}
          {isCallingRemoteFeatureSetupInfoApi ? (
            <Skeleton style={{ width: '90px', margin: '5px 0px 0px 5px' }} />
          ) : featureSetupData?.updateDt ? (
            dayjs(featureSetupData?.updateDt).format('YYYY/MM/DD HH:mm')
          ) : (
            t('remoteDevice.label.notYet')
          )}
        </Typography>

        <>
          {accountInfo.featurePermissions.GROUPMANAGEMENT?.REMOTECONTROLDATAPROTECTION?.includes(
            FEATURE_PERMISSION.GROUPMANAGEMENT.REMOTECONTROLDATAPROTECTION
              .VIEWDETAILS,
          ) && (
            <Typography
              sx={{
                fontWeight: 'bold',
                lineHeight: '20px',
                fontSize: '14px',
                color: '#313133',
                margin: '20px 0px 12px',
                letterSpacing: '0.09px',
              }}
            >
              {isCallingRemoteFeatureSetupInfoApi ? (
                <Skeleton
                  style={{
                    width: '90px',
                    height: '25px',
                  }}
                />
              ) : (
                t('remoteDevice.label.dataProtection')
              )}
            </Typography>
          )}
        </>

        {isCallingRemoteFeatureSetupInfoApi ? (
          <>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between' }}
            ></Box>

            {/* remote input field */}
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                columnGap: '30px',
                rowGap: '20px',
                marginBottom: '20px',
              }}
            >
              <Box sx={{ position: 'relative', width: '100%' }}>
                <Skeleton sx={{ width: '60px', height: '20px' }} />
                <Skeleton sx={{ width: '200px', height: '38px' }} />
              </Box>
              <Box sx={{ width: '100%' }}>
                <Skeleton sx={{ width: '100px', height: '20px' }} />
                <Skeleton sx={{ width: '200px', height: '38px' }} />
              </Box>
              <Box sx={{ width: '100%' }}>
                <Skeleton sx={{ width: '200px', height: '20px' }} />
                <Skeleton sx={{ width: '200px', height: '38px' }} />
              </Box>
              <Box sx={{ width: '100%' }}>
                <Skeleton sx={{ width: '200px', height: '38px' }} />
              </Box>
            </Box>

            <Box className={`${classes.noWifiBox} ${'no-wifi-box'}`}>
              <Typography
                sx={{
                  fontWeight: 'medium',
                  lineHeight: '24px',
                  fontSize: '16px',
                  color: '#313133',
                  marginBottom: '16px',
                }}
              >
                <Skeleton sx={{ width: '200px', height: '38px' }} />
              </Typography>
              <Button
                aria-label="Update"
                disabled
                sx={{
                  p: '9px 16px',
                  height: '38px',
                  background: 'grey',
                  borderRadius: '6px',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: '#007EB4',
                  },
                  '&.Mui-disabled': {
                    color: 'white',
                    background: '#009DE1',
                    opacity: 0.5,
                  },
                }}
                disableRipple
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#FFFFFF',
                  }}
                >
                  {/* <Skeleton
                  style={{ width: '90px', margin: '5px 0px 0px 5px' }}
                /> */}
                </Typography>
              </Button>
            </Box>
          </>
        ) : (
          <>
            <NewWifi
              ref={formRef}
              key={'generalSettings'}
              // @ts-ignore:next-line
              formField={featureSetupData}
              onClose={onClose}
              featureLimitListData={featureLimitListData!}
              isCallingRemoteFeatureSetupPostApi={
                isCallingRemoteFeatureSetupPostApi
              }
              featureSetupResult={featureSetupResult}
              onClickRemoteFeatureSetupPost={onClickRemoteFeatureSetupPost}
              setSaveButtonDisable={setSaveButtonDisable}
              setIsMenuOpen={setMenuOpen}
            />
          </>
        )}

        {/* add new wifi settings end  */}
      </SimpleBarReact>

      {/* add new wifi modal submit and cancle button  */}

      <Box
        className=""
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '12px',
          borderTop: '1px solid #D9D9D9',
          pt: '12px',
          pb: '12px',
          maxWidth: '744px',
          mx: '32px',
        }}
      >
        <Button
          aria-label="Cancel"
          disabled={isCallingRemoteFeatureSetupPostApi}
          onClick={() => {
            // GA Tag
            sendGAEvent('press_btn', 'btn_name', 'cancel');
            onClose();
          }}
          // disabled
          sx={{
            p: '10px 40px',
            height: '44px',
            background: '#FFFFFF',
            border: '1px solid #D9D9D9',
            borderRadius: '6px',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: ' rgba(0, 0, 0, 0.04)',
            },
            '&.Mui-disabled': {
              color: 'white',
              background: '#FFFFFF',
              opacity: 0.5,
            },
          }}
          disableRipple
        >
          <Typography
            sx={{
              fontSize: '16px',
              lineHeight: '24px',
              color: '#313133',
              fontWeight: 'medium',
            }}
          >
            {t('remoteDevice.button.cancel')}
          </Typography>
        </Button>

        <Button
          aria-label="Update"
          type="submit"
          disabled={isSaveButtonDisable || isCallingRemoteFeatureSetupPostApi}
          sx={{
            p: '10px 40px',
            height: '44px',
            background: '#009DE1',
            borderRadius: '6px',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#007EB4',
            },
            '&.Mui-disabled': {
              color: 'white',
              background: '#009DE1',
              opacity: 0.5,
            },
          }}
          disableRipple
          onClick={() => {
            // GA Tag
            sendGAEvent('press_btn', 'btn_name', 'save');
            triggerSubmit();
          }}
        >
          {isCallingRemoteFeatureSetupPostApi ? (
            <Typography
              sx={{
                fontSize: '16px',
                lineHeight: '24px',
                color: '#FFFFFF',
                fontWeight: 'medium',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              {t('remoteDevice.label.saving')}
              <Loader colorCode="#fff" />
            </Typography>
          ) : (
            <Typography
              sx={{
                fontSize: '16px',
                lineHeight: '24px',
                color: '#FFFFFF',
                fontWeight: 'medium',
              }}
            >
              {t('remoteDevice.button.save')}
            </Typography>
          )}
        </Button>
      </Box>
    </Dialog>
  );
});

export default RemoteDeviceSetupDialog;
