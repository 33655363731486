/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import clsx from 'clsx';

// import { useForm, Controller } from 'react-hook-form';
import { Box, Typography, Skeleton, Button, Tooltip } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { useStyles } from '../Templates/CommonStyle';
import { sendGAEvent } from '../../hooks/useGAEvent';
import ToggleSwitch from '../Atoms/ToggleSwitch';
import { ReactComponent as SaveEditIcon } from '../../images/icon/save-edit.svg';
import { ReactComponent as SaveIcon } from '../../images/icon/save.svg';
import { ReactComponent as Close } from '../../images/icon/close-blue.svg';
import { ReactComponent as EditPenIcon } from '../../images/icon/edit-pen.svg';
import { ReactComponent as CloseIcon } from '../../images/icon/close.svg';
import { ReactComponent as RestoreModalIcon } from '../../images/icon/pocketalk-modal.svg';
import { ReactComponent as FoundDeviceIcon } from '../../images/icon/found_device.svg';
import LostModalIcon from '../../images/icon/lost_mode.svg';
import InputPinCodeLostMode from '../Atoms/InputPinCodeLostMode';
import Loader from '../Atoms/Loader';
import { FEATURE_PERMISSION, ROLE_CODE } from '../../constants/constants';
import useAccountInfo from '../../hooks/useAccountInfo';
import useDeviceLostModeEnable from '../../hooks/useDeviceLostModeEnable';
import useDeviceSaveHistoryLocal from '../../hooks/useDeviceSaveHistoryEnable';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import { useGetLostModePin } from '../../hooks/useGetLostModePin';
import useFactoryReset from '../../hooks/useRemoteFeatureFactoryReset';
import useDeviceLostModeDisable from '../../hooks/useDeviceLostModeDisable';
import { statusColorFn } from '../../utils/devicelist/deviceListUtil';
import { DEVICE_SAVE_HISTORY_LOCAL_API_RESULT_CODE } from '../../apis/callDeviceSaveHistoryEnable';

// custom dialog design
const CustomDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    width: '500px',
    borderRadius: '12px',
    padding: '24px',
    alignSelf: 'flex-start',
    marginTop: '180px',
    '& .MuiDialogTitle-root': {
      padding: 0,
      fontSize: '18px',
      lineHeight: '26px',
      maxWidth: '330px',
      // minHeight: '27px',
      fontWeight: 'bold',
    },
    '& .MuiDialogContent-root': {
      padding: 0,
    },
    '& .MuiDialogActions-root': {
      padding: 0,
      marginLeft: 'auto',
    },
  },
}) as typeof Dialog;

// factory restore dialog
const FactoryDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    width: '560px',
    borderRadius: '12px',
    padding: '24px',
    alignSelf: 'flex-start',
    marginTop: '180px',
    '& .MuiDialogTitle-root': {
      padding: 0,
      fontSize: '18px',
      lineHeight: '26px',
      maxWidth: '356px',
      fontWeight: 'bold',
      // wordSpacing: '.2em',
      // letterSpacing: '-1px'
    },
    '& .MuiDialogContent-root': {
      padding: 0,
    },
    '& .MuiDialogActions-root': {
      padding: 0,
      marginLeft: 'auto',
    },
  },
}) as typeof Dialog;

type Props = {
  imei: string;
  lostModeStatus: boolean;
  saveHistoryStatus: boolean;
  isCallingUpdatePinApi: boolean;
  pinUpdateResultCode: string;
  onClickUpdatePin: (updatePinCode: string) => void;
  onClickLostModeChange: (value: boolean) => void;
  factoryReset: boolean;
  setFactoryReset: React.Dispatch<React.SetStateAction<boolean>>;
  isCallingDetailsApi: boolean;
  modeName: string;
  modeStatus: string;
  statusColor: string;
  lostModeDt: string;
  factoryResetDt: string;
  // featureLimitDt: string;
  lostModeView?: boolean;
  lostModeEdit?: boolean;
  factoryResetView?: boolean;
  factoryResetEdit?: boolean;
  productModel: string;
};

const DeviceDetailsStatusCard = React.memo<Props>(
  ({
    imei,
    lostModeStatus,
    saveHistoryStatus,
    onClickUpdatePin,
    isCallingUpdatePinApi,
    pinUpdateResultCode,
    onClickLostModeChange,
    factoryReset,
    setFactoryReset,
    isCallingDetailsApi,
    modeName,
    modeStatus,
    statusColor,
    lostModeDt,
    // featureLimitDt,
    factoryResetDt,
    lostModeView,
    lostModeEdit,
    factoryResetView,
    factoryResetEdit,
    productModel,
  }): JSX.Element => {
    const classes = useStyles();
    const { t } = useSwitchLocaleLanguage();
    const { accountInfo } = useAccountInfo();
    const [lostMode, setLostMode] = useState<boolean>(lostModeStatus);
    const [saveHistory, setSaveHistory] = useState<boolean>(saveHistoryStatus);
    const [addPin, setAddPin] = useState(false); // Enable Pin code Value add card
    const [lostModalOpen, setLostModalOpen] = useState(false); // Enable Pin code control modal
    const [saveHistoryModalOpen, setSaveHistorytModalOpen] = useState(false); // Enable Pin code control modal
    const [enablePinCodeValue, setEnablePinCodeValue] = useState<string>(''); // Enable Pin code Value
    const [inputFormError, setInputFormError] = useState(false);
    const { isCallingApi, onClickDeviceLostModeEnable, errorType } =
      useDeviceLostModeEnable();
    const {
      onClickDeviceSaveHistoryLocal,
      saveHistoryErrorType,
      isCallingSaveHistoryApi,
    } = useDeviceSaveHistoryLocal();
    const { currentPin, onClickGetLostPin, isCallingGetPinApi } =
      useGetLostModePin();
    const [updatePinCodeValue, setUpdatePinCodeValue] =
      useState<string>(currentPin);
    const [pinEditState, setPinEditState] = useState(false); // Enable Pin code Value edit state
    const [factoryDialog, setFactoryDialog] = useState(false); // Enable factory reset modal
    const {
      isCallingFactoryResetApi,
      factoryResetErrorType,
      onClickFactoryReset,
    } = useFactoryReset();
    const {
      isCallingDisableApi,
      onClickDeviceLostModeDisable,
      errorTypeDisable,
    } = useDeviceLostModeDisable();
    dayjs.extend(utc);

    // start lost mode handlers
    const enableLostMode = () => {
      /*eslint-disable*/
      if (
        enablePinCodeValue?.toString().length === 6 &&
        typeof parseInt(enablePinCodeValue) === 'number'
      ) {
        onClickDeviceLostModeEnable(imei, enablePinCodeValue, true);

        // GA tag
        sendGAEvent('edit_text_num', 'txt_name', 'input_pin_code');
        sendGAEvent(
          'edit_text_num',
          'input_pin_code_value',
          enablePinCodeValue.toString(),
        );
        sendGAEvent('press_btn', 'btn_name', 'save_pin_code');
      } else {
        setInputFormError(true);
      }

      // GA tag
      sendGAEvent('press_btn', 'txt_name', imei.toString());
      sendGAEvent('press_btn', 'txt_name', enablePinCodeValue.toString());
    };
    // lost Mode Enable
    useEffect(() => {
      if (errorType === 'OK' && !lostMode && !isCallingApi) {
        onClickLostModeChange(true);
        setLostMode(true);
        setAddPin(false);
      }
    }, [errorType, isCallingApi]);

    const disableLostMode = () => {
      onClickDeviceLostModeDisable(imei, false);
    };
    // lost Mode disable
    useEffect(() => {
      if (errorTypeDisable === 'OK' && lostMode && !isCallingDisableApi) {
        onClickLostModeChange(false);
        setLostMode(false);
        setLostModalOpen(false);
      }
      if (
        errorTypeDisable === 'LOST_MODE_ALREADY_DISABLED' &&
        lostMode &&
        !isCallingDisableApi
      ) {
        onClickLostModeChange(false);
        setLostMode(false);
        setLostModalOpen(false);
      }
    }, [errorTypeDisable, isCallingDisableApi]);

    useEffect(() => {
      setPinEditState(false);
      if (lostModeStatus) {
        setLostMode(true);
      }
      if (!lostModeStatus) {
        setLostMode(false);
      }
    }, [lostModeStatus]);

    const handleLostModalOpen = () => {
      setLostModalOpen(true);
    };
    const handleLostModalClose = () => {
      setLostModalOpen(false);
    };
    // lost modal pin set open
    const handleLostModalPinOpen = () => {
      setEnablePinCodeValue('');
      setAddPin(true);
    };
    const handleLostModalPinClose = () => {
      setAddPin(false);
      setEnablePinCodeValue('');
    };
    // lost modal pin
    const pinEditStateOpen = () => {
      getUpdatePin();
      if (!isCallingGetPinApi) {
        setUpdatePinCodeValue(currentPin);
        setPinEditState(true);
      }
    };
    const getUpdatePin = () => {
      if (lostMode) {
        onClickGetLostPin(imei);
        setUpdatePinCodeValue('');
      }
      setUpdatePinCodeValue(currentPin);
    };

    // Pin Code Updated
    const handlePinUpdate = () => {
      if (
        updatePinCodeValue?.toString().length === 6 &&
        typeof parseInt(updatePinCodeValue) === 'number'
      ) {
        onClickUpdatePin(updatePinCodeValue);

        // GA tag
        sendGAEvent('edit_text_num', 'txt_name', 'input_pin_code');
        sendGAEvent(
          'edit_text_num',
          'input_pin_code_value',
          enablePinCodeValue.toString(),
        );
      } else {
        setInputFormError(true);
      }

      // GA tag
      sendGAEvent('press_btn', 'txt_name', imei.toString());
      sendGAEvent('press_btn', 'txt_name', enablePinCodeValue.toString());
    };
    // Pin Updated and modal close
    useEffect(() => {
      if (
        pinUpdateResultCode === 'SUCCESSFULLY_UPDATED' &&
        !isCallingUpdatePinApi
      ) {
        setPinEditState(false);
        onClickGetLostPin(imei);
      }
    }, [isCallingUpdatePinApi, pinUpdateResultCode]);
    useEffect(() => {
      setUpdatePinCodeValue(currentPin);
    }, [currentPin]);
    useEffect(() => {
      setInputFormError(false);
    }, [enablePinCodeValue, updatePinCodeValue, addPin, pinEditState]);

    // End lost mode handlers
    // Save Translation History

    useEffect(() => {
      if (saveHistoryStatus) {
        setSaveHistory(true);
      } else {
        setSaveHistory(false);
      }
    }, [saveHistoryStatus]);

    useEffect(() => {
      if (
        !isCallingSaveHistoryApi &&
        saveHistoryErrorType ===
          DEVICE_SAVE_HISTORY_LOCAL_API_RESULT_CODE.SUCCESSFULLY_CREATED
      ) {
        setSaveHistory(!saveHistory);
      }
      if (!isCallingSaveHistoryApi) {
        setSaveHistorytModalOpen(false);
      }
    }, [isCallingSaveHistoryApi]);

    console.log(productModel);

    const saveHistoryAPICall = () => {
      onClickDeviceSaveHistoryLocal(imei, !saveHistory);
    };
    // start factory restore dialog
    const factoryDialogOpen = () => {
      setFactoryDialog(true);
    };
    const factoryDialogClose = () => {
      setFactoryDialog(false);
    };
    const handleFactorySettings = () => {
      onClickFactoryReset(parseInt(imei));
    };
    useEffect(() => {
      if (
        (!isCallingFactoryResetApi && factoryResetErrorType === 'OK') ||
        (!isCallingFactoryResetApi &&
          factoryResetErrorType === 'DEVICE_RESTORE_INITIATED')
      ) {
        setFactoryDialog(false);
        setFactoryReset(!factoryReset);
      }
    }, [isCallingFactoryResetApi, factoryResetErrorType]);
    // end factory restore dialog
    console.log(saveHistory, 'saveHistory');
    return (
      <Box
        className={`${classes.StatusDetailsCard} device-details-status-card`}
      >
        <Box
          sx={{
            '@media screen and (min-width:1550px)': {
              width: '218px',
              marginRight: '20px',
            },
          }}
          className="device-details-status-card-item"
        >
          <Box
            sx={{ marginBottom: '37px' }}
            className="device-details-status-card-item-box"
          >
            <Typography className={classes.itemName}>
              {' '}
              {t('deviceInfo.label.featureLimit')}
            </Typography>
            {isCallingDetailsApi ? (
              <>
                <Skeleton
                  variant="text"
                  animation="wave"
                  sx={{ p: '0px', width: '117px', height: '20px' }}
                />
                {/* <Skeleton
                  variant="text"
                  animation="wave"
                  sx={{ p: '0px', width: '117px', height: '20px' }}
                /> */}
              </>
            ) : (
              <>
                <Typography className={classes.itemData}>
                  {modeName ? modeName : '-'}
                </Typography>
                {/* <Typography className={classes.itemDataLight}>
                  {featureLimitDt
                    ? dayjs(featureLimitDt).format('YYYY/MM/DD HH:mm')
                    : '-'}
                </Typography> */}
              </>
            )}
          </Box>
          {!isCallingDetailsApi && (
            <>
              {!lostModeView &&
                accountInfo.featurePermissions?.DEVICEMANAGEMENT?.DEVICELOSTMODE?.includes(
                  FEATURE_PERMISSION.DEVICEMANAGEMENT.DEVICELOSTMODE
                    .VIEWDETAILS,
                ) && (
                  <Box
                    sx={{ marginBottom: '17px' }}
                    className="device-details-status-card-item-box"
                  >
                    <Typography className={classes.itemName}>
                      {t('deviceInfo.label.lostMode')}
                    </Typography>
                    {isCallingDetailsApi ? (
                      <>
                        <Skeleton
                          variant="text"
                          animation="wave"
                          sx={{ p: '0px', width: '117px', height: '20px' }}
                        />
                        <Skeleton
                          variant="text"
                          animation="wave"
                          sx={{ p: '0px', width: '117px', height: '20px' }}
                        />
                      </>
                    ) : (
                      <>
                        {lostMode || addPin ? (
                          <Button
                            sx={{ padding: '0px' }}
                            onClick={() => {
                              if (lostModeEdit) {
                                return;
                              }
                              if (lostMode) {
                                handleLostModalOpen();
                              }
                              if (addPin) {
                                handleLostModalPinClose();
                                // setEnablePinCodeValue('');
                              }

                              // GA tag
                              sendGAEvent(
                                'press_btn',
                                'btn_name',
                                'lost_mode_select',
                              );
                              sendGAEvent(
                                'press_btn',
                                'lost_mode_select_value',
                                'ON',
                              );
                              sendGAEvent(
                                'press_btn',
                                'txt_name',
                                imei.toString(),
                              );
                            }}
                            disableRipple
                            disabled={
                              !accountInfo.featurePermissions?.DEVICEMANAGEMENT?.DEVICELOSTMODE?.includes(
                                FEATURE_PERMISSION.DEVICEMANAGEMENT
                                  .DEVICELOSTMODE.EDIT,
                              )
                            }
                          >
                            <Typography className={classes.itemDataOnOff}>
                              {t('deviceInfo.label.on')}
                            </Typography>
                            <ToggleSwitch switchStatus />
                          </Button>
                        ) : (
                          <Button
                            sx={{ padding: '0px' }}
                            onClick={() => {
                              if (lostModeEdit) {
                                return;
                              }
                              handleLostModalOpen();
                              // GA tag
                              sendGAEvent(
                                'press_btn',
                                'btn_name',
                                'lost_mode_select',
                              );
                              sendGAEvent(
                                'press_btn',
                                'lost_mode_select_value',
                                'OFF',
                              );
                              sendGAEvent(
                                'press_btn',
                                'txt_name',
                                imei.toString(),
                              );
                            }}
                            disableRipple
                            disabled={
                              !accountInfo.featurePermissions?.DEVICEMANAGEMENT?.DEVICELOSTMODE?.includes(
                                FEATURE_PERMISSION.DEVICEMANAGEMENT
                                  .DEVICELOSTMODE.EDIT,
                              )
                            }
                          >
                            <Typography className={classes.itemDataOnOff}>
                              {t('deviceInfo.label.off')}
                            </Typography>
                            <ToggleSwitch switchStatus={false} />
                          </Button>
                        )}
                        <Typography className={classes.itemDataLight}>
                          {/* 2022/11/11 12:00 */}
                          {lostModeDt
                            ? dayjs(lostModeDt).utc().format('YYYY/MM/DD HH:mm')
                            : '-'}
                        </Typography>
                      </>
                    )}
                  </Box>
                )}
              {/* start factory reset */}
              {!factoryResetView &&
                accountInfo.featurePermissions?.DEVICEMANAGEMENT?.DEVICEINITIALIZATION?.includes(
                  FEATURE_PERMISSION.DEVICEMANAGEMENT.DEVICEINITIALIZATION
                    .VIEWDETAILS,
                ) && (
                  <Box className="device-details-status-card-item-box">
                    <Typography className={classes.itemName}>
                      {t('deviceInfo.label.factoryReset')}
                    </Typography>
                    {isCallingDetailsApi ? (
                      <>
                        <Skeleton
                          variant="text"
                          animation="wave"
                          sx={{ p: '0px', width: '117px', height: '20px' }}
                        />
                        <Skeleton
                          variant="text"
                          animation="wave"
                          sx={{ p: '0px', width: '117px', height: '20px' }}
                        />
                      </>
                    ) : (
                      <>
                        {!factoryResetEdit &&
                          accountInfo.featurePermissions?.DEVICEMANAGEMENT?.DEVICEINITIALIZATION?.includes(
                            FEATURE_PERMISSION.DEVICEMANAGEMENT
                              .DEVICEINITIALIZATION.EDIT,
                          ) && (
                            <Button
                              sx={{
                                color: '#E1341E',
                                textTransform: 'none',
                                padding: '0px',
                                '&:hover': {
                                  color: '#CC240F',
                                  textDecoration: 'underline',
                                },
                              }}
                              aria-label="reset"
                              onClick={() => {
                                factoryDialogOpen();
                                // GA tag
                                sendGAEvent(
                                  'press_btn',
                                  'btn_name',
                                  'restore_factory_setting',
                                );
                                sendGAEvent(
                                  'press_btn',
                                  'txt_name',
                                  imei.toString(),
                                );
                              }}
                              disabled={
                                isCallingApi ||
                                !accountInfo.featurePermissions?.DEVICEMANAGEMENT?.DEVICEINITIALIZATION?.includes(
                                  FEATURE_PERMISSION.DEVICEMANAGEMENT
                                    .DEVICEINITIALIZATION.EDIT,
                                )
                              }
                            >
                              <Typography
                                sx={{
                                  height: '20px',
                                  fontSize: '14px',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {t('deviceInfo.label.resetNow')}
                              </Typography>
                            </Button>
                          )}
                        <Typography className={classes.itemDataLight}>
                          {factoryResetDt
                            ? dayjs(factoryResetDt).format('YYYY/MM/DD HH:mm')
                            : '-'}
                        </Typography>
                      </>
                    )}
                  </Box>
                )}
              {/* end factory reset */}
            </>
          )}
        </Box>

        <Box
          sx={{
            '@media screen and (max-width:1550px)': {
              minWidth: '230px',
            },
            '@media screen and (max-width:1270px)': {
              minWidth: '200px',
            },
          }}
          className="device-details-status-card-item"
        >
          <Box
            sx={{
              marginBottom: '30px',
            }}
            className="device-details-status-card-item-box"
          >
            <Typography className={classes.itemName}>
              {' '}
              {t('deviceInfo.label.status')}
            </Typography>
            {isCallingDetailsApi ? (
              <Skeleton
                variant="text"
                animation="wave"
                sx={{
                  p: '0px',
                  width: '130px',
                  height: '25px',
                  borderRadius: '8px',
                }}
              />
            ) : (
              <Box
                sx={{
                  color: modeStatus ? '#FFFFFF' : 'black',
                  backgroundColor: statusColorFn(statusColor),
                  padding: modeStatus ? '4px 10px' : 0,
                  borderRadius: '16px',
                  display: 'inline-block',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '13px',
                  }}
                >
                  {modeStatus ? modeStatus : '-'}
                </Typography>
              </Box>
            )}
          </Box>
          {/* start generate lost mode pin */}
          <Box sx={{ minHeight: '87px' }}>
            {addPin && !lostModeView && (
              <Box className="device-details-status-card-item-box">
                <Typography className={classes.itemName}>
                  {t('deviceInfo.label.lostModePin')}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '6px',
                  }}
                  className="lost-mode-input-group"
                >
                  <InputPinCodeLostMode
                    width="120px"
                    height="30px"
                    value={enablePinCodeValue}
                    setValue={setEnablePinCodeValue}
                    isError={inputFormError}
                  />
                  {inputFormError && (
                    <Box className={classes.pinCodeError}>
                      <p style={{ padding: '0', margin: '0' }}>
                        {t('lostMode.message.pinDigNum')}
                      </p>
                    </Box>
                  )}
                  <Box className="name-btns">
                    <Tooltip
                      PopperProps={{
                        modifiers: [
                          {
                            name: 'offset',
                            options: {
                              offset: [0, -7.5],
                            },
                          },
                        ],
                      }}
                      componentsProps={{
                        tooltip: {
                          className: classes.iconTooltipStyle,
                        },
                      }}
                      title={t('common.tooltip.save')}
                      arrow
                    >
                      <IconButton
                        id="saveButton"
                        disabled={
                          enablePinCodeValue?.toString().length !== 6 ||
                          isCallingApi
                        }
                        className={clsx(
                          classes.groupCreateSaveIcon,
                          classes.deviceDetailsIconBtn,
                        )}
                        sx={{
                          padding: isCallingApi ? '0px !important' : '6px',
                        }}
                        onClick={() => {
                          enableLostMode();
                          // GA tag
                          sendGAEvent('press_btn', 'btn_name', 'yes');
                        }}
                      >
                        {!isCallingApi ? (
                          <SaveIcon />
                        ) : (
                          <Loader colorCode="#939598" />
                        )}
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      PopperProps={{
                        modifiers: [
                          {
                            name: 'offset',
                            options: {
                              offset: [0, -7.5],
                            },
                          },
                        ],
                      }}
                      componentsProps={{
                        tooltip: {
                          className: classes.iconTooltipStyle,
                        },
                      }}
                      title={t('common.tooltip.cancel')}
                      arrow
                    >
                      <IconButton
                        className={clsx(
                          classes.closeIconBtn,
                          classes.deviceDetailsIconBtn,
                        )}
                        aria-label="delete"
                        onClick={() => {
                          handleLostModalPinClose();
                          // GA tag
                          sendGAEvent('press_btn', 'btn_name', 'exit');
                          sendGAEvent('press_btn', 'txt_name', imei.toString());
                          sendGAEvent(
                            'press_btn',
                            'btn_name',
                            'cancel_pin_code',
                          );
                        }}
                        disabled={isCallingApi}
                      >
                        <Close />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
                <Typography className={classes.itemDataLight}>
                  {t('deviceInfo.label.digit')}
                </Typography>
              </Box>
            )}
            {/* end generate lost mode pin */}
            {/* start edit lost mode pin */}
            {lostMode &&
              !lostModeView &&
              accountInfo.featurePermissions?.DEVICEMANAGEMENT?.DEVICELOSTMODE?.includes(
                FEATURE_PERMISSION.DEVICEMANAGEMENT.DEVICELOSTMODE.EDIT,
              ) &&
              !isCallingDetailsApi && (
                <Box>
                  <Typography className={classes.itemName}>
                    {t('deviceInfo.label.lostModePin')}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '6px',
                      height: '30px',
                      alignItems: 'center',
                      // position: 'relative',
                    }}
                  >
                    {!pinEditState && (
                      <>
                        <Typography
                          sx={{
                            fontSize: '8px',
                            fontWeight: 'bold',
                            marginRight: '6px',
                          }}
                        >
                          ●●●●●●
                        </Typography>
                        {!lostModeEdit && (
                          <Tooltip
                            title={t('common.tooltip.editPin')}
                            arrow
                            sx={{ marginTop: 0 }}
                            PopperProps={{
                              modifiers: [
                                {
                                  name: 'offset',
                                  options: {
                                    offset: [0, -7],
                                  },
                                },
                              ],
                            }}
                            componentsProps={{
                              tooltip: {
                                className: classes.iconTooltipStyle,
                              },
                            }}
                          >
                            <Button
                              id="editButton"
                              sx={{ marginTop: '0px !important' }}
                              className={classes.deviceNameEditButton}
                              onClick={() => {
                                pinEditStateOpen();
                              }}
                            >
                              <EditPenIcon
                                style={{ width: '14.638', height: '14.638' }}
                              />
                            </Button>
                          </Tooltip>
                        )}
                      </>
                    )}
                    {pinEditState && (
                      <>
                        {isCallingGetPinApi ? (
                          <Skeleton sx={{ width: '120px' }} />
                        ) : (
                          <InputPinCodeLostMode
                            width="120px"
                            height="30px"
                            value={updatePinCodeValue}
                            setValue={setUpdatePinCodeValue}
                            isError={inputFormError}
                          />
                        )}
                        {inputFormError && (
                          <Box className={classes.pinCodeError}>
                            <p style={{ padding: '0', margin: '0' }}>
                              {t('lostMode.message.pinDigNum')}
                            </p>
                          </Box>
                        )}
                        <Tooltip
                          PopperProps={{
                            modifiers: [
                              {
                                name: 'offset',
                                options: {
                                  offset: [0, -7.5],
                                },
                              },
                            ],
                          }}
                          componentsProps={{
                            tooltip: {
                              className: classes.iconTooltipStyle,
                            },
                          }}
                          title={t('common.tooltip.save')}
                          arrow
                        >
                          <IconButton
                            id="saveButton"
                            className={clsx(
                              classes.nameEditSaveIconBtn,
                              classes.deviceDetailsIconBtn,
                            )}
                            sx={{
                              padding: isCallingUpdatePinApi
                                ? '0px !important'
                                : '6px',
                            }}
                            onClick={() => {
                              handlePinUpdate();
                              // GA tag
                              sendGAEvent('press_btn', 'btn_name', 'update');
                            }}
                            disabled={
                              currentPin === updatePinCodeValue ||
                              updatePinCodeValue.length < 6 ||
                              isCallingUpdatePinApi
                            }
                          >
                            {!isCallingUpdatePinApi ? (
                              <SaveEditIcon />
                            ) : (
                              <Loader colorCode="#939598" />
                            )}
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          PopperProps={{
                            modifiers: [
                              {
                                name: 'offset',
                                options: {
                                  offset: [0, -7.5],
                                },
                              },
                            ],
                          }}
                          componentsProps={{
                            tooltip: {
                              className: classes.iconTooltipStyle,
                            },
                          }}
                          title={t('common.tooltip.cancel')}
                          arrow
                        >
                          <IconButton
                            className={clsx(
                              classes.closeIconBtn,
                              classes.deviceDetailsIconBtn,
                            )}
                            aria-label="delete"
                            onClick={() => {
                              setPinEditState(false);
                              // GA tag
                              sendGAEvent('press_btn', 'btn_name', 'cancel');
                            }}
                            disabled={isCallingUpdatePinApi}
                          >
                            <Close />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </Box>
                  {pinEditState && (
                    <Typography className={classes.itemDataLight}>
                      {t('deviceInfo.label.digit')}
                    </Typography>
                  )}
                </Box>
              )}
          </Box>
          {/* end edit lost mode pin */}
          {/* start save history toggle */}
          {accountInfo.roleCode === ROLE_CODE.ADMIN && (
            <Box>
              <Typography className={classes.itemName} sx={{ width: '175px' }}>
                {t('deviceInfo.label.saveHistory')}
              </Typography>
              {isCallingDetailsApi ? (
                <Skeleton
                  variant="text"
                  animation="wave"
                  sx={{ p: '0px', width: '117px', height: '20px' }}
                />
              ) : (
                <>
                  <Button
                    sx={{ padding: '0px' }}
                    disableRipple
                    onClick={() => setSaveHistorytModalOpen(true)}
                  >
                    <Typography className={classes.itemDataOnOff}>
                      {saveHistory
                        ? t('deviceInfo.label.on')
                        : t('deviceInfo.label.off')}
                    </Typography>
                    <ToggleSwitch switchStatus={saveHistory} />
                  </Button>
                </>
              )}
            </Box>
          )}
          {/* end save history toggle */}
        </Box>
        {/* enable disable lost mode start */}
        <CustomDialog
          aria-labelledby="customized-dialog-title"
          open={lostModalOpen}
        >
          <Box className={`${classes.dialogBox} res-dialog`}>
            <Box className={`${classes.dialogIcon} res-dialog-icon`}>
              {lostMode ? (
                <FoundDeviceIcon />
              ) : (
                <img
                  src={LostModalIcon}
                  alt="LostModeIcon"
                  style={{ width: '100%' }}
                />
              )}
            </Box>
            <Box sx={{ width: '100%' }}>
              <DialogTitle className="res-dialog-title">
                {lostMode
                  ? t('lostMode.message.foundDevice')
                  : t('lostMode.message.enableLostMode')}

                <IconButton
                  aria-label="close"
                  onClick={() => {
                    handleLostModalClose();
                    // GA tag
                    sendGAEvent('press_btn', 'btn_name', 'exit');
                    sendGAEvent('press_btn', 'txt_name', imei.toString());
                  }}
                  className={classes.dialogCloseIcon}
                >
                  <CloseIcon className={classes.close} />
                </IconButton>
              </DialogTitle>
              <DialogContent
                className={`${classes.dialogContentCommon} res-dialog-text`}
              >
                <Typography variant="body2" className={classes.dialogSubTitle}>
                  {lostMode
                    ? t('lostMode.message.disableLostMode')
                    : t('lostMode.message.enableLostModeYes')}
                </Typography>
              </DialogContent>
              <DialogActions
                className={`${classes.dialogActionsCommon} res-dialog-btns`}
              >
                <Button
                  className={classes.btnAction}
                  onClick={() => {
                    handleLostModalClose();
                    // GA tag
                    sendGAEvent('press_btn', 'btn_name', 'no');
                  }}
                  disableRipple
                >
                  {t('lostMode.button.no')}
                </Button>
                {lostMode ? (
                  <Button
                    autoFocus
                    className={`${classes.btnAction} ${classes.btnActionPrimary}`}
                    onClick={() => {
                      disableLostMode();
                      // GA tag
                      sendGAEvent('press_btn', 'btn_name', 'yes');
                      sendGAEvent('press_btn', 'txt_name', imei.toString());
                    }}
                    disableRipple
                  >
                    {isCallingDisableApi ? (
                      <Loader colorCode="#fff" />
                    ) : (
                      t('lostMode.button.yes')
                    )}
                  </Button>
                ) : (
                  <Button
                    autoFocus
                    className={`${classes.btnAction} ${classes.btnActionPrimary}`}
                    onClick={() => {
                      handleLostModalPinOpen();
                      handleLostModalClose();
                      // GA tag
                      sendGAEvent('press_btn', 'btn_name', 'yes');
                    }}
                    disableRipple
                  >
                    {t('lostMode.button.yes')}
                  </Button>
                )}
              </DialogActions>
            </Box>
          </Box>
        </CustomDialog>
        {/* Save history */}
        <CustomDialog
          aria-labelledby="customized-dialog-title"
          open={saveHistoryModalOpen}
        >
          <Box className={`${classes.dialogBox} res-dialog`}>
            <Box className={`${classes.dialogIcon} res-dialog-icon`}>
              <FoundDeviceIcon />
            </Box>
            <Box sx={{ width: '100%' }}>
              <DialogTitle className="res-dialog-title">
                {saveHistory
                  ? t('saveHistoryLocal.saveHistoryOnDialogTitleDisable')
                  : t('saveHistoryLocal.saveHistoryOnDialogTitleEnable')}

                <IconButton
                  aria-label="close"
                  onClick={() => {
                    setSaveHistorytModalOpen(false);
                  }}
                  className={classes.dialogCloseIcon}
                >
                  <CloseIcon className={classes.close} />
                </IconButton>
              </DialogTitle>
              <DialogContent
                className={`${classes.dialogContentCommon} res-dialog-text`}
              >
                <Typography variant="body2" className={classes.dialogSubTitle}>
                  {saveHistory
                    ? t('saveHistoryLocal.saveHistoryOffDialogContent')
                    : t('saveHistoryLocal.saveHistoryOnDialogContent')}
                </Typography>
              </DialogContent>
              <DialogActions
                className={`${classes.dialogActionsCommon} res-dialog-btns`}
              >
                <Button
                  className={classes.btnAction}
                  onClick={() => {
                    setSaveHistorytModalOpen(false);
                  }}
                  disableRipple
                >
                  {t('lostMode.button.no')}
                </Button>

                <Button
                  autoFocus
                  className={`${classes.btnAction} ${classes.btnActionPrimary}`}
                  onClick={() => {
                    saveHistoryAPICall();
                  }}
                  disableRipple
                >
                  {isCallingSaveHistoryApi ? (
                    <Loader colorCode="#fff" />
                  ) : (
                    t('lostMode.button.yes')
                  )}
                </Button>
              </DialogActions>
            </Box>
          </Box>
        </CustomDialog>
        {/* restore factory dialog start */}
        <FactoryDialog
          aria-labelledby={t('lostMode.label.restoreFactory')}
          open={factoryDialog}
        >
          <Box className={`${classes.dialogBox} res-dialog`}>
            <Box className={`${classes.dialogIcon} res-dialog-icon`}>
              <RestoreModalIcon />
            </Box>
            <Box sx={{ width: '100%' }}>
              <DialogTitle className="res-dialog-title">
                {t('lostMode.message.restoreFactoryConfirmation')}
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    factoryDialogClose();
                    // GA tag
                    sendGAEvent('press_btn', 'btn_name', 'exit');
                  }}
                  className={classes.dialogCloseIcon}
                >
                  <CloseIcon className={classes.close} />
                </IconButton>
              </DialogTitle>
              <DialogContent
                sx={{ marginTop: '10px' }}
                className={`${classes.dialogContentCommon} res-dialog-text`}
              >
                <Typography variant="body2" className={classes.dialogSubTitle}>
                  {t('lostMode.message.afterReset')}
                </Typography>
              </DialogContent>
              <DialogActions
                className={`${classes.dialogActionsCommon} res-dialog-btns`}
              >
                <Button
                  className={classes.btnAction}
                  onClick={() => {
                    factoryDialogClose();
                    // GA tag
                    sendGAEvent('press_btn', 'btn_name', 'no');
                  }}
                  disableRipple
                >
                  {t('lostMode.button.no')}
                </Button>
                <Button
                  autoFocus
                  className={`${classes.btnAction} ${classes.btnActionPrimary}`}
                  onClick={() => {
                    handleFactorySettings();
                    // GA tag
                    sendGAEvent('press_btn', 'btn_name', 'yes');
                    sendGAEvent('press_btn', 'txt_name', imei.toString());
                  }}
                  disableRipple
                >
                  {isCallingFactoryResetApi ? (
                    <Loader colorCode="#fff" />
                  ) : (
                    t('lostMode.button.yes')
                  )}
                </Button>
              </DialogActions>
            </Box>
          </Box>
        </FactoryDialog>
        {/* end restore factory dialog*/}
      </Box>
    );
  },
);

export default DeviceDetailsStatusCard;
